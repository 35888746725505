<template>
  <el-row v-if="mainButtontext === 'createIcon'">
    <el-button
      style="padding: 0px; outline: transparent; border: transparent"
      @click="dialogStart"
      circle
    >
      <el-icon style="vertical-align: center; width: 45px; height: 45px">
        <CirclePlusFilled style="width: 45px; height: 45px; color: #67c23a" />
      </el-icon>
    </el-button>
  </el-row>

  <el-row v-else-if="mainButtontext === 'editIcon'">
    <el-button
      @click="dialogStart"
      type="success"
      :icon="operationIcons.edit"
      circle
    />
  </el-row>

  <Button v-else @click="dialogStart" :text="mainButtontext" />

  <el-dialog
    top="40px"
    v-model="dialogVisible"
    :title="dialogTitle"
    width="35%"
    :before-close="handleClose"
  >
    <!-- Form Render -->
    <v-form-render
      :form-json="formJson"
      :form-data="formData"
      :option-data="optionData"
      ref="vFormRef"
    ></v-form-render>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">Відміна</el-button>
        <el-button type="primary" @click="addNewOne">
          {{ buttonLabelDo }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { inject, ref } from "vue";
import { ElMessageBox } from "element-plus";
import Button from "./Button";
import { Edit, CirclePlusFilled } from "@element-plus/icons";

export default {
  name: "Dialog",
  components: {
    Button,
    CirclePlusFilled,
  },
  props: {
    mainButtontext: String,
    dialogTitle: String,
    buttonText: String,
    confirmText: String,
    buttonLabelDo: String,
    functionName: String,
    row: Object,
    formSchemaName: String,
    getDataMethodName: String,
  },

  data() {
    return {
      dialogVisible: ref(false),
      // operationIcons: {
      //   edit: Edit,
      //   add: CirclePlusFilled,
      // },
      // FormRender
      formJson: {},
      formData: {},
      optionData: {},
    };
  },
  methods: {
    async dialogStart() {
      if (this.row) {
        //  UPDATE
        const dataFormShema = await this.methods.getFormShemaByName(
          this.formSchemaName
        );
        if (!dataFormShema) {
          return;
        }
        // dataUser

        const userData = await this.methods[this.getDataMethodName](
          this.row.id
        );
        if (!userData) {
          return;
        }
        this.formData = userData;
        this.formJson = dataFormShema;

        this.dialogVisible = true;
        return;
      }

      // Creating New
      const dataFormShema = await this.methods.getFormShemaByName(
        this.formSchemaName
      );
      if (!dataFormShema) {
        return;
      }
      this.formJson = dataFormShema;
      this.dialogVisible = true;
      this.vFormRef.resetForm();
    },
    handleClose(done) {
      ElMessageBox.confirm(this.confirmText, {
        confirmButtonText: "Так",
        cancelButtonText: "Ні",
        type: "warning",
      })
        .then(() => {
          /** close without saving */

          // console.log("close without saving");
          done();
        })
        .catch(() => {
          /** continue writing */
          // console.log("continue writing");
          // catch error
        });
    },
    cancel() {
      this.dialogVisible = false;
    },
    addNewOne() {
      this.vFormRef
        .getFormData()
        .then(async (formData) => {
          // Form Validation OK
          const result = await this.methods[this.functionName](formData);
          result ? (this.dialogVisible = false) : "";
        })
        .catch(() => {
          // Form Validation failed
        });
    },
  },
  setup() {
    const { state, methods } = inject("store");
    const store = inject("store");
   const operationIcons= {
        edit: Edit,
        add: CirclePlusFilled,
      };
    const vFormRef = ref(null);
    return { store, state, methods, vFormRef, operationIcons };
  },
};
</script>

<style>
.administrator .el-dialog {
  border-radius: 10px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}

.el-overlay.is-message-box .el-message-box {
  width: 310px !important;
  height: 110px !important;
}
.form-group {
  margin-bottom: 20px;
}
.selectWrapper {
  margin-bottom: 20px;
}

.form-group-title {
  display: block;
  font-size: 16px;
  line-height: 28px;
  color: #171717;
  margin-bottom: 12px;
}
.addAdmin .input {
  margin-bottom: 12px;
}

.pass-green .el-input__inner {
  background-color: rgba(0, 255, 0, 0.2);
}
.pass-warning .el-input__inner {
  background-color: rgba(255, 255, 0, 0.2);
}
.warning-field {
  color: red;
}
</style>
