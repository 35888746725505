<template>
  <div class="clients">
    <ClientsTable />
  </div>
</template>

<script>
import ClientsTable from "../components/ClientsTable";

export default {
  name: "Clients",
  components: {
    ClientsTable,
  },
};
</script>
<style scoped>
.clients {
  margin: 20px 20px 0 20px;
}
</style>
