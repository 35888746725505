<template>
  <div class="sideBar">
    <!-- <div class="logo">
      
      <img
        class="pointer"
        @click="toMainPage($router)"
        src="../assets/img/logo-white.png"
        alt="Logo"
      />
    </div> -->
    <div class="menu">
      <ul class="side-bar">
        <router-link
          v-for="link in this.state.user_role==='administrator'?   this.adminLinks : this.managerLinks"
          :key="link.url"
          tag="li"
          class="link"
          :to="link.url"
          :exact="link.exact"
         
          @click="() => methods.confirmRedirectToPage($router, link.url)"
        >
          <span class="text">{{ link.title }}</span>
        </router-link>
        <!-- <el-button
          style="
            padding-left: 0;
            font-weight: 600;
            color: #a5a8b0;
            background: transparent;
            outline: transparent;
            border: transparent;"
          type="info"
          @click="singOut($router)"
          >Вихід</el-button
        > -->
      </ul>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "Sidebar",
  components: {},
  methods: {
    toMainPage(router) {
      if(this.state.user_role==='administrator'){
        router.push("/Widgets");
        return;
      }
      router.push("/Clients");
    },
    
    singOut(router) {
      localStorage.clear();
      router.go(0);
    },
  },
  data: () => ({
    managerLinks: [
      { title: "Клієнти", url: "/Clients" },
      { title: "Договори клієнтів", url: "/Documents" },
      { title: "Замовлення клієнтів", url: "/Orders" },
      { title: "Платежі клієнтів", url: "/Payments" },
      { title: "Аквізитор та Менеджер", url: "/Acquisitor" },
    ],
    adminLinks: [
      { title: "Віджети", url: "/Widgets" },
      { title: "Користувачі", url: "/Users" },
      { title: "Продукти", url: "/Products" },
      { title: "Клієнти", url: "/Clients" },
      { title: "Договори клієнтів", url: "/Documents" },
      { title: "Замовлення клієнтів", url: "/Orders" },
      { title: "Платежі клієнтів", url: "/Payments" },
      { title: "Аквізитор та Менеджер", url: "/Acquisitor" },
      { title: "Налаштування", url: "/Settings" },
    ],
    links: [
    
      
    ],
  }),
  setup() {
    const { state, methods } = inject("store");

    return {
      state,
      methods,
    };
  },
};
</script>

<style scoped>
.sideBar {
  background-color: #53565A;
  margin-top: 2px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 49px;
}
.pointer {
  cursor: pointer;
}
.side-bar {
 
  display: flex;
  flex-flow: column;
  margin-top: 38px;
  align-items: flex-start;
  padding-left: 60px;
  /* background-color: #373f4f; */
}
.link {
  margin-bottom: 25px;
  text-decoration: none;
  color: #a5a8b0;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--primary-font-family);
}
.text {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.router-link-active {
  color: white;
  position: relative;
}
.router-link-exact-active::after {
  content: "";
  position: absolute;
  left: -60px;
  border-left: 20px solid #64a70b;
  height: 45px;
  transform: translateY(-58%);
}
</style>
