<template>
    <MainPage />
</template>

<script>
import { provide } from "vue";
import store from "@/store";
import MainPage from "./views/MainPage";

export default {
    components: {
        MainPage,
    },
    setup() {
        // document.title = 'e-com Admin'
        console.log("version 1.0.93");
        provide("store", store);
        // console.log(this.$keycloak.authenticated);
    },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import "reset.css";
@import "./generalStyles.scss";
</style>
