<template>
  <div class="products">
    <div class="header">
      <h1 class="heading-title">Продукти</h1>
      <!-- <h3 class="heading">Список продуктів</h3> -->
      <Dialog
        mainButtontext="createIcon"
        confirm-text="Зміни не буде збережено, ви впевнені?"
        dialogTitle="Створюємо новий продукт"
        button-label-do="Створити"
        function-name="fetchAddNewProduct"
        formSchemaName="createProduct"
        :key="state.newAdminData"
      />
    </div>
    <ProductsTable />
  </div>
</template>

<script>
import ProductsTable from "../components/ProductsTable";
import Dialog from "../components/Dialog";
import { inject } from "vue";

export default {
  name: "Products",
  components: {
    ProductsTable,
    Dialog,
  },
  setup() {
    const { state, methods } = inject("store");
    return {
      state,
      methods,
    };
  },
};
</script>

<style scoped>
.products {
  margin: 20px 20px 0 20px;
}

.heading-title {
  font-weight: 600;
  font-size: 20px;
  font-family: Raleway;
  margin-bottom: 32px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.heading {
  font-weight: 600;
  font-size: 16px;
  font-family: Raleway;
}
</style>
