<template>
  <div class="payments">
    <PaymentsTable />
  </div>
</template>

<script>
import PaymentsTable from "../components/PaymentsTable";

export default {
  name: "Payments",
  components: {
    PaymentsTable,
  },
};
</script>
<style scoped>
.payments {
  margin: 20px 20px 0 20px;
}
</style>
