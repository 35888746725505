<template>
 
  <el-table
    :data="store.state.dataArray"
    :default-sort="{ prop: 'data', order: 'descending' }"
    
    style="width: 100%; font-family: 'Open Sans', sans-serif"
    class="table"
    :border="true"
  >
    <el-table-column
      v-for="(item, index) in clientFieldArr"
      :prop="item.name"
      sortable
      width="auto"
      :min-width="item.fieldLength"
      :key="item"
      :column-key="item.name"
    >
      <template #default="props">
        <a
          v-if="'channel_name' === item.name"
          :href="props.row[item.name]"
          target="_blank"
          rel="noopener noreferrer"
          >{{ props.row[item.name] }}</a
        >
        <p :style="{width:'100%'}" v-else-if="'widget_code' === item.name">
          <svg
            :style="
              props.row.active
                ? { width: '10px', marginRight: '5px', fill: '#67c23a' }
                : { width: '10px', marginRight: '5px', fill: '#f56c6c' }
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M3.984 12q0-3.281 2.367-5.648t5.648-2.367 5.648 2.367 2.367 5.648-2.367 5.648-5.648 2.367-5.648-2.367-2.367-5.648z"
            ></path>
          </svg>
          {{ props.row[item.name]
          }}
        </p>

        <p v-else>{{ props.row[item.name] }}</p>
      </template>

      <template #header>
        <div class="search-block" :class="{ show: item.show }">
          <el-input
            @input="filter"
            v-model="item.search"
            size="small"
            placeholder="Type to search"
            class="search-input"
            clearable
            @click.stop
          />
        </div>
        <span>{{ item.fieldName }}</span>
        <span
          @click.stop="toggleShow('show', 'search', index)"
          class="search-icon-filter"
        >
          <el-row>
            <el-button
              v-if="item.dataType === 'String'"
              :icon="operationIcons.searchIcon"
              size="small"
              circle
              class="icon"
            />
          </el-row>
        </span>
      </template>
    </el-table-column>
    <el-table-column label="Operations" width="150">
      <template #default="scope">
        <el-button
          @click="edit(scope.$index, scope.row)"
          type="success"
          :icon="operationIcons.edit"
          circle
        />
        <el-button
          @click="makeCopy(scope.$index, scope.row)"
          type="info"
          :icon="operationIcons.copy"
          circle
        />
        <el-button
          @click="handleDelete(scope.$index, scope.row)"
          type="danger"
          :icon="operationIcons.delete"
          circle
        />
      </template>
    </el-table-column>
  </el-table>

  <el-dialog
    top="60px"
    :before-close="onCloseModal"
    :lock-scroll="true"
    v-model="store.state.dialogVisible"
    width="65%"
  >
    <CustomEditorVue v-if="store.state.dialogVisible" />
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="openEditorJson">JSON</el-button>
        <el-button @click="onCloseModal" type="danger" plain
          >Відмінити</el-button
        >
        <el-button type="warning" @click="store.methods.saveJsonTemp"
          >Зберегти</el-button
        >
        <el-button type="success" @click="() => store.methods.setJsonCode()"
          >Застосувати</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- JSON Editor Custom -->
  <el-dialog
    :lock-scroll="true"
    v-model="isOpenEditorJson"
    :title="'Id: ' + store.state.jsonWrittableId.widget_id"
    width="40%"
  >
    <el-input
      class="EditorJson"
      v-model="EditorJson"
      :rows="2"
      type="textarea"
      placeholder="Please input"
    />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isOpenEditorJson = false" type="danger" plain
          >Відмінити</el-button
        >
        <el-button type="success" @click="confirmEditorJson"
          >Підтвердити</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { Search, Edit, Delete, CopyDocument } from "@element-plus/icons";
import { inject, toRefs } from "vue";
import services from "../services";
// import VJsoneditor from 'v-jsoneditor/src/index'
import { ElMessage, ElMessageBox } from "element-plus";
import CustomEditorVue from "./CustomEditor/CustomEditor.vue";



export default {
  name: "WidgetsTable",

  components: {
   
    CustomEditorVue,
  },
  data() {
    return {
      methodName: "fetchWidgetList",
      jsonParam: {
        getJsonLink: "adm_get_widget_by_id", //from postman
        setJsonLink: "adm_put_widget_by_id", //from postman
        dataField: "widget_object", //name arr from response data
      },
      centerDialogVisible: false,
     
      
  
      clientFieldArr:[
        {
          name: "widget_code",
          fieldName: "Код віджету",
          show: "",
          search: "",
          fieldLength: 150,
          dataType: "String",
        },
        {
          name: "widget_name",
          fieldName: "Назва",
          show: "",
          search: "",
          fieldLength: 200,
          dataType: "String",
        },
        {
          name: "widget_type",
          fieldName: "Тип",
          show: "",
          search: "",
          fieldLength: 120,
          dataType: "String",
        },
        {
          name: "widget_template",
          fieldName: "Шаблон",
          show: "",
          search: "",
          fieldLength: 120,
          dataType: "String",
        },
        {
          name: "channel_name",
          fieldName: "Канал",
          show: "",
          search: "",
          fieldLength: 300,
          dataType: "String",
        },
        
      ],

     

      tableData: [],
      isOpenEditorJson: false,

      EditorJson: "",
      
    };
  },
 

  methods: {
    myFunc() {},
    async filter() {
      const queryObj = Object.assign(
        {},
        ...this.clientFieldArr.map((item) => ({ [item.name]: item.search }))
      );
      await this.store.methods.fetchWithTimer(queryObj);
    },
    async edit(index, row) {
      await this.store.methods.getJsonCode({ widget_id: row.widget_id });

      // this.store.state.setJsonLink = this.setJsonLink
      // await this.store.methods.getWidgetCode(row.widget_id)
    },
    async handleDelete(index, row) {
      ElMessageBox.confirm(`Видалити "${row.widget_code}"?`, {
        confirmButtonText: "Так",
        cancelButtonText: "Нi",
        type: "warning",
      })
        .then(async () => {
          const { status_code } = await services.fetchSetDeleteWidget(
            row.widget_id
          );
          if (status_code === "200") {
            await this.store.methods.fetchDataList();
            ElMessage({
              message: `"${row.widget_code}" успiшно видалено`,
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
    async onCloseModal() {
      ElMessageBox.confirm(`Ви не зберегли зміни. Закрити?`, {
        confirmButtonText: "Так",
        cancelButtonText: "Нi",
        type: "warning",
      })
        .then(async () => {
          this.store.state.dialogVisible = false;
        })
        .catch(() => {});
    },

    async makeCopy(index, row) {
      const { status_code } = await services.fetchSetDuplicateWidget(
        row.widget_id
      );
      if (status_code === "200") {
        await this.store.methods.fetchDataList();
        ElMessage({
          message: `"${row.widget_code}" успiшно скопійовано`,
          type: "success",
        });
      }
    },

    toggleShow(field, searchField, index) {
      if (!this.clientFieldArr[index][field]) {
        this.clientFieldArr[index][field] = true;
      } else if (
        this.clientFieldArr[index][field] &&
        !this.clientFieldArr[index][searchField]
      ) {
        this.clientFieldArr[index][field] = false;
      }
    },
    openEditorJson() {
      this.EditorJson = JSON.stringify(this.store.state.textareaText);
      this.isOpenEditorJson = true;
    },
    confirmEditorJson() {
      this.store.state.textareaText = JSON.parse(this.EditorJson);
      this.store.state.tempTextareaText = this.store.state.textareaText;
      this.isOpenEditorJson = false;
    },
  
  },

  async mounted() {
    await this.store.methods.setFetchParams(
      // "fetchWidgetList", //from services
      "adm_get_widget_by_id", //from postman
      "adm_put_widget_by_id", //from postman
      "widget_object" //name arr from response data)
    );
    await this.store.methods.setFetchMethodName(this.methodName);
    await this.store.methods.fetchDataList();
    this.store.state.dataField = this.clientFieldArr.find(
      ({ dataType }) => dataType === "Date"
    )?.name;
    
  },
  setup() {
    const store = inject("store");
    const  operationIcons= {
        edit: Edit,
        delete: Delete,
        copy: CopyDocument,
        searchIcon:Search,
      };
    return {
      store,
      operationIcons,
      ...toRefs(store.state),
    };
  },
};
</script>
<style>
.table-style {
  font-family: Raleway, serif;
}


.el-message-box {
  width: 1500px !important;
  height: 900px !important;
}

.el-textarea__inner {
  height: 700px !important;
}
.comment .el-textarea__inner {
  height: 300px !important;
  width: 100% !important;
}

.el-dialog .jsoneditor {
  border: #509f00 solid 1px;
  border-radius: 8px;
}

.EditorJson {
  max-height: 400px;
}

.EditorJson .el-textarea__inner {
  max-height: 400px;
  overflow-y: auto;
}

.el-dialog * {
  font-family: "open sans" !important;
}

.jsoneditor .jsoneditor-menu {
  background-color: #509f00;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.jsoneditor-tree {
  border-radius: 8px;
}
</style>
