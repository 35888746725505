import { createApp } from "vue";
import App from "./App";
import ElementPlus from "element-plus";
import router from "./router/router";
import store from "./store";
// import JsonViewer from "vue3-json-viewer";
// import "vue3-json-viewer/dist/index.css";
import VJsoneditor from "v-jsoneditor";
import "element-plus/dist/index.css";
import "@/variables.css";

import uk from "element-plus/es/locale/lang/uk";
import "dayjs/locale/uk";
import dayjs from "dayjs";

import VForm3 from "vform3-builds";
import "vform3-builds/dist/designer.style.css";

import Keycloak from "keycloak-js";

const clientKeycloak = new Keycloak({
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
});

clientKeycloak
    .init({ onLoad: "login-required" })
    .then(() => {
        window.$keycloak = clientKeycloak;
        createApp(App)
            .use(store)
            .use(ElementPlus, {
                locale: uk,
            })
            .use(dayjs)
            .use(router)
            .use(VJsoneditor)
            .use(VForm3)
            .mount("#app");
    })
    .catch((e) => {
        console.log(e);
    });
