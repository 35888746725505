<template>
  <div class="administrator">
    <h1 class="heading-title">Налаштування</h1>
    <div class="header">
      <!-- <h3 class="heading">Список віджетів з комісіями еквайрингу</h3> -->
    </div>
    <SettingsTable />
    <!--    <div class="pages-footer">-->
    <!--      <PaginationClients/>-->
    <!--    </div>-->
  </div>
</template>

<script>
// import Button from "../components/Button";
// import ButtonClear from "../components/ButtonClear";
// import SelectSettings from "../components/SelectSettings";
import SettingsTable from "../components/SettingsTable";

export default {
  name: "Settings",
  components:{
    // Button,
    // ButtonClear,
    // SelectSettings
    SettingsTable,
  }
}
</script>

<style scoped>
.administrator{
  margin: 20px 20px 0 20px;
}
.heading-title{
  font-weight: 600;
  font-size: 20px;
  font-family: Raleway;
  margin-bottom: 32px;
}
.header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.heading{
  font-weight: 600;
  font-size: 16px;
  font-family: Raleway;
}
.pages-footer{
  display: flex;
}
</style>
