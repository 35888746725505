<template>
  <div class="documents">
    <DocumentsTable />
  </div>
</template>

<script>
import DocumentsTable from "../components/DocumentsTable";

export default {
  name: "Documents",
  components: {
    DocumentsTable,
  },
};
</script>
<style scoped>
.documents {
  margin: 20px 20px 0 20px;
}
</style>
