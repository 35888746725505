import {createRouter, createWebHistory} from "vue-router";
// import store from "@/store"
import Login from "@/views/Login";
import Widgets from "../views/Widgets";
// import CreateOnlineProducts from "../views/CreateOnlineProducts";
import Users from "../views/Users";
import Products from "../views/Products.vue";
import Clients from "../views/Clients";
import Documents from "../views/Documents";
import Orders from "../views/Orders";
import Payments from "../views/Payments";
import Settings from "../views/Settings";
import Acquisitor from "../views/Acquisitor.vue"
// import StaticWidgets from "../views/StaticWidgets";
// import {accessFunc} from "../store";

const pagesList = [
    Login,
    Widgets,
    Users,
    Products,
    Orders,
    Documents,
    Clients,
    Payments,
    Settings,
    Acquisitor,
]
 /** Just need import and add component with right name */

const routes = pagesList.map(item => ({path: `/${item.name}`, component: item}))

const router = createRouter({
    history: createWebHistory(),
    routes
})
//
// router.beforeEach(async (to, from, next) => {
//     // await accessFunc("accessToken")
//     const token = localStorage.getItem("token");
//     if(to.fullPath !== '/' && !token) {
//         console.log(to.fullPath);
//      await next('/');
//     }
//     if(to.fullPath === '/' && token) {
//         console.log(to.fullPath);
//        await next('/OnlineProducts');
//     }
//        if(to.fullPath !== '/' && token && store.state.updateDataOnCurrentPage) {
//            console.log("updateDataOnCurrentPage was change");
//           await store.methods.updateDataOnCurrentPage(false)
//       await  next(to.fullPath)
//     }
//     await next();
// });

export default router;
