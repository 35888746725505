<template lang="">
  <el-tabs v-model="activeName" type="card" class="demo-tabs">
    <el-tab-pane label="Зміна пароля" name="password"/>
    <!-- <el-tab-pane label="Завдання" name="tasks"/>
    <el-tab-pane label="Повідомлення" name="bells"/> -->
  </el-tabs>
  
  
  <div class='inner_box'>
    <div v-if="activeName==='password'">
        <ResetPassForm @submitNewPass='onChangePassword'/>
    </div>

    <!-- <div v-if="activeName==='tasks'">
        <h2>Завдання</h2>
    </div>


    <div v-if="activeName==='bells'">
        <h2>Повідомлення</h2>
    </div> -->

   
  </div>
</template>
<script>
import ResetPassForm from "./ResetPassForm.vue"
export default {
  name:'userPersonalSettings',
  emits: ["submit"],
  components:{
    ResetPassForm
  },
  data() {
    return {
        activeName:'password',
    };
  },
  methods: {
    
    onChangePassword(value){
        this.$emit("submit", {value, operation:'changePassword'});
    }
  },
};
</script>
<style lang="css" scoped>
.inner_box{
    height: 300px;
    overflow-y: auto;
}
</style>
