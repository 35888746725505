<template>
    <div class="documents-heading">
    <h1 class="documents-heading-title">Договори клієнтів системи</h1>
    <TableCustomizer
      @onConfirm="onConfirmTableParams"
      :fieldArr="clientFieldArrBase"
      :profileList="this.store.state.table_view"
    />
  </div>
 
  <el-table
      :data="store.state.dataArray"
      :border="true"
       style="width: 100%; font-family: 'Open Sans', sans-serif;"
      class="table"
  >

    <el-table-column v-for="(item, index) in clientFieldArr" :prop=item.name sortable
                     width="auto"
                     :min-width=item.fieldLength
                     :key="item" :column-key=item.name>
      <template #header>
        <div class="search-block" :class="{'show':item.show}">
          <div v-if="item.dataType === 'Date'" class="block">
            <el-date-picker
                v-model="item.search"

                type="daterange"

                unlink-panels
                range-separator="до"
                start-placeholder="Початок"
                end-placeholder="Кінець"
                format="DD.MM.YYYY"
                @change="filter"
            />
          </div>
          <el-input v-else @input="filter" v-model="item.search" size="small" placeholder="Type to search"
                    class="search-input" clearable @click.stop/>
        </div>
        <span>{{ item.fieldName }}</span>
        <span @click.stop="toggleShow('show', 'search', index)" class="search-icon-filter">
          <el-row>
<!--    <el-button v-if="item.dataType==='String'" :icon="searchIcon" size="small" circle class="icon" />-->
    <el-button :icon="searchIcon" size="small" circle class="icon" />
          </el-row>
        </span>
      </template>

      <template #default="scope">
        <div v-if="item.dataType === 'Date'"> {{ dayjs(scope.row[item.name]).format('DD/MM/YYYY HH:mm:ss') }}</div>
        <div :class="{'green': scope.row[item.name] === 'Оплачено'}" v-else> {{ scope.row[item.name] }}</div>

      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {Search} from "@element-plus/icons";
import {inject} from "vue";
import dayjs from "dayjs";

import TableCustomizer from "./TableCustomizer/TableCustomizer.vue";

export default {
  name: "DocumentsTable",
  components: {
    TableCustomizer,
  },
  data() {
    return {
      methodName:'fetchContractList',
      dayjs:dayjs,
      value:'',
      delay: 500,
      timer: '',
      searchText: '',
      clientFieldArrBase: [
        {
          name: 'appl_code',
          fieldName: 'Заявка',
          show: '',
          search: '',
          fieldLength: 200,
          dataType:"String",
        },
        {
          name: 'polis_number',
          fieldName: 'Номер договору',
          show: '',
          search: '',
          fieldLength: 200,
           dataType:"String",
        },
        {
          name: 'contr_date',
          fieldName: 'Дата',
          show: '',
          search: '',
          fieldLength: 260,
           dataType:"Date",
        },
        {
          name: 'contr_status',
          fieldName: 'Статус',
          show: '',
          search: '',
          fieldLength: 130,
           dataType:"String",
        },
        {
          name: 'full_name',
          fieldName: 'ПІБ',
          show: '',
          search: '',
          fieldLength: 330,
           dataType:"String",
        },
        {
          name: 'phone_num',
          fieldName: 'Телефон',
          show: '',
          search: '',
          fieldLength: 150,
           dataType:"String",
        },
        {
          name: 'email',
          fieldName: 'E-mail',
          show: '',
          search: '',
          fieldLength: 230,
           dataType:"String",
        },
       /* {
          name: 'amount',
          fieldName: 'Сума',
          show: '',
          search: '',
          fieldLength: 150,
           dataType:"Number",
        },
        {
          name: 'fishka_score',
          fieldName: 'Бали Fishka',
          show: '',
          search: '',
          fieldLength: 180,
           dataType:"String",
        },
        {
          name: 'url',
          fieldName: 'Url',
          show: '',
          search: '',
          fieldLength: 150,
           dataType:"String",
        },
        {
          name: 'products',
          fieldName: 'Продукти',
          show: '',
          search: '',
          fieldLength: 150,
           dataType:"String",
        },
        {
          name: 'delivery',
          fieldName: 'Спосіб доставки',
          show: '',
          search: '',
          fieldLength: 200,
           dataType:"String",
        },*/
      ],

      clientFieldArr:[],
    }
  },

  methods: {
    async filter() {
      const queryObj = Object.assign({}, ...this.clientFieldArr.map(item => ({[item.name]: item.search})))
      await this.store.methods.fetchWithTimer(queryObj)
    },

    toggleShow(field, searchField, index) {
      if (!this.clientFieldArr[index][field]) {
        this.clientFieldArr[index][field] = true
      } else if (this.clientFieldArr[index][field] && !this.clientFieldArr[index][searchField]) {
        this.clientFieldArr[index][field] = false
      }
    },

      // TableParams
   async onConfirmTableParams(value) {
      this.store.methods.setTableParams(value, 'contract')
      this.store.state.table_view = value;
      this.makeCustomTable();
    },

    makeCustomTable() {
      if (!this.store.state.table_view?.length) {
        this.clientFieldArr = this.clientFieldArrBase;
        this.store.state.table_view = this.clientFieldArrBase.map(el=>el.name)
        return;
      }


      // Check new and Old Params
      const newParams = this.store.state.table_view.filter(name=>this.clientFieldArrBase.find(el=>el.name === name));
      if(!newParams.length){
        this.clientFieldArr = this.clientFieldArrBase;
        this.store.state.table_view = this.clientFieldArrBase.map(el=>el.name)
        return;
      }



      const newClientFieldArr = this.store.state.table_view.map((name) => {
        return this.clientFieldArrBase.find((el) => el.name === name);
      });
      this.clientFieldArr = [];

      setTimeout(() => {
        this.clientFieldArr = newClientFieldArr;
      }, 0);
    },
  },

  async mounted() {
    this.store.methods.setFetchMethodName(this.methodName)
    await this.store.methods.fetchDataList()
    this.store.state.dataField = this.clientFieldArr.find(({dataType})=> dataType==="Date")?.name
    this.makeCustomTable();
  },
  setup() {
    const store = inject('store');
    const searchIcon = Search;
    return {
      store,
      searchIcon
    }
  }
}
</script>

<style lang="css">

.documents-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.documents-heading-title {
  font-weight: 600;
  font-size: 20px;
  font-family: Raleway;
}

.search-input-block {
  display: flex;
  justify-content: end;
}

.search-block {
  transition: 0.2s;
  overflow: hidden;
  height: 0;
}

.show {
  height: 40px;
}

.search-input {
  margin: 0 20px 40px 0;
}

.search-icon-filter {
  position: absolute;
  right: 10px;
  font-size: 10px;
  padding: 0;
}

.search-icon-filter .icon {
  position: absolute;
  right: 0;
  top: -4px;
  font-size: 15px;
}

.el-table.table .cell {
  overflow: visible;
}

</style>
