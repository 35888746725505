import { reactive } from "vue";
import services from "../services";
import { ElMessage } from "element-plus";

const toISOStringWithTimezone = (date, index) => {
    const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, "0");
    return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate() + index) +
        "T00:00:00.000Z"
    );
};

const state = reactive({
    token: "",
    updateDataOnCurrentPage: false,
    tablesClientHeight: "750",
    query_params: {},
    newAdminData: {},
    fixAdminDataById: {},
    page: 1,
    limit: 10,

    user_role: "",
    user_id: null,

    loading: false,
    loadingText: "Зачекайте, будь ласка ... ",

    jsonWrittableId: {},
    textareaText: "",
    tempTextareaText: "",

    fetchMethodName: "fetchApplicationList",
    dialogVisible: false,
    router: "",
    arrayClientLength: 10,

    table_view: [],

    search_obj: {},
    query_size: 1,
    delay: 500,
    timer: "",
    dataArray: [],
});

const methods = {
    updateDataOnCurrentPage(value) {
        state.updateDataOnCurrentPage = value;
    },
    setPage(value) {
        state.page = value;
    },
    setLimit(value) {
        state.limit = value;
    },
    async setDataArray(value) {
        state.dataArray = value;
        if (!value[0]) {
            return;
        }

        await state.dataArray.forEach((item) => {
            if (item.amount !== undefined) {
                item.amount = +item.amount;
            }
            if (item.commission !== undefined) {
                item.commission = +item.commission;
            }
        });
    },

    async setQueryParams(obj = {}) {
        state.query_params = obj;
        state.query_params[state.dataField] = state.query_params[state.dataField]
            ? state.query_params[state.dataField].map((item, index) =>
                  toISOStringWithTimezone(item, index)
              )
            : "";
    },

    resetQueryData() {
        state.query_params = {};
        state.page = 1;
        state.limit = 20;
    },
    setTablesClientHeight(value) {
        state.tablesClientHeight = value - 230;
    },
    setFetchMethodName(method) {
        state.fetchMethodName = method;
        state.query_params = {};
        state.table_view = [];
        // console.log(method);
        // console.log(field);
    },

    setQuery_size(value) {
        state.query_size = Math.ceil(value / state.limit);
    },
    login(token) {
        state.token = token;
    },

    async logout() {
        localStorage.removeItem("token");
        await methods.redirectToLoginPage();
    },

    async redirectToLoginPage(router) {
        state.token = "";
        await router.push("/");
    },

    async getFormShemaByName(formName) {
        const { data } = await services.fetchFormSchema(formName);
        return data;
    },

    async redirectToPage(router) {
        // try {
        await router.push("/Settings");
        //     await router.push('/Orders')
        // } catch {
        //     await router.go(0);
        // }
    },

    async confirmRedirectToPage(router, url) {
        await router.push(url);
    },

    async doLogin(loginData, router) {
        state.loading = true;
        const result = await services.fetchDoLogin(loginData);
        state.loading = false;
        if (result?.data) {
            const { data } = result;
            localStorage.setItem("token", data.token);
            await services.setToken();
            state.token = data.token;

            state.user_role = data.user_role;
            state.user_id = data.user_id;

            const path = state.user_role !== "administrator" ? "/Clients" : "/Widgets";
            await router.push(path);
        }
        return result;
    },

    async accessToken(router) {
        // console.log(window.$keycloak);
        // const token = localStorage.getItem("token");
        const token = window.$keycloak.token;

        if (!token) {
            await methods.redirectToLoginPage(router);
            return;
        }
        try {
            const { data } = await services.fetchAccessToken(token);
            if (data.message === "User login") {
                state.token = token;
                state.user_role = data.user_role;
                state.user_id = data.user_id;
                const path =
                    state.user_role !== "administrator" ? "/Clients" : "/Widgets";
                await router.push(path);
                return;
            }
        } catch (error) {
            // await methods.redirectToLoginPage(router);
            window.$keycloak.logout();
            state.loading = false;
        }
    },

    fetchWithTimer(queryParams = {}) {
        clearTimeout(state.timer);

        state.timer = setTimeout(async () => {
            await methods.setQueryParams(queryParams);
            await methods.updateDataOnCurrentPage(true);
            const { limit, page, query_params } = state;
            // const dateStart = toISOStringWithTimezone(query_params.contr_date[0]);
            // console.log(query_params.contr_date[0].toJSON());
            // const dateEnd = toISOStringWithTimezone(query_params.contr_date[1]);
            // const newQueryParams = {...query_params, contr_date: [dateStart, dateEnd]}
            const { status_code, data } = await services[state.fetchMethodName]({
                query_params,
                limit,
                page,
            });
            if (status_code === "200") {
                await methods.setQuery_size(data.query_size);
                await methods.setDataArray(data.data_set);
            }
        }, this.delay);
    },
    async fetchDataList() {
        state.loading = true;
        await methods.setDataArray([]);
        const { limit, page, query_params } = state;
        try {
            const { status_code, data } = await services[state.fetchMethodName]({
                query_params,
                limit,
                page,
            });
            if (status_code === "200") {
                await methods.setQuery_size(data.query_size);
                await methods.setDataArray(data.data_set);
                if (data?.table_view) {
                    state.table_view = data.table_view;
                }
            }
            state.loading = false;
        } catch (error) {
            state.loading = false;
            if (error?.response?.request?.status === 401) {
                // window.location.reload();
                window.$keycloak.logout();
                return;
            }
            ElMessage({
                message: `Request failed with status code 500`,
                type: "error",
            });
        }
    },

    async fetchAddNewAdmin(formData) {
        const { status_code } = await services.fetchAddNewAdmin(formData);
        if (status_code === "200") {
            await methods.fetchDataList();
            ElMessage({
                message: `Користувач "${formData.user_name}" успішно створено.`,
                type: "success",
            });
            return true;
        }
        return false;
    },
    async fetchRemoveAdminById(id) {
        const { status_code } = await services.fetchRemoveAdminById(id);
        if (status_code === "200") {
            await methods.fetchDataList();
        }
        state.loading = false;
        return status_code;
    },
    async fetchUpdateAdmin(formData) {
        const { status_code } = await services.fetchUpdateAdmin(formData);
        if (status_code === "200") {
            await methods.fetchDataList();
            ElMessage({
                message: `Дані користувача"${formData.user_name}" успішно оновлено.`,
                type: "success",
            });
            return true;
        }
        return false;
    },
    async getUserDataById(id) {
        const { data } = await services.fetchUserDataById(id);
        return data;
    },

    async fetchAddNewProduct(formData) {
        const { status_code } = await services.fetchAddNewProduct(formData);
        if (status_code === "200") {
            await methods.fetchDataList();
            ElMessage({
                message: `Продукт "${formData.description}" успішно створено.`,
                type: "success",
            });
            return true;
        }
        return false;
    },
    async fetchUpdateProduct(formData) {
        const { status_code } = await services.fetchUpdateProduct(formData);
        if (status_code === "200") {
            await methods.fetchDataList();
            ElMessage({
                message: `Продукт "${formData.description}" успішно оновлено.`,
                type: "success",
            });
            return true;
        }
        return false;
    },
    async fetchRemoveProductById(formData) {
        const { status_code } = await services.fetchRemoveProductById(formData);
        if (status_code === "200") {
            await methods.fetchDataList();
            ElMessage({
                message: `Продукт "${formData.description}" успішно видалено.`,
                type: "success",
            });
        }
    },
    async getProductDataById(id) {
        const { data } = await services.fetchProductDataById(id);
        return data;
    },

    getTempWidgetJson(value) {
        if (!value?.timeStamp) {
            state.tempTextareaText = value;
        }
    },

    async setFetchParams(get, set, field) {
        state.getJsonLink = get;
        state.setJsonLink = set;
        state.fieldJson = field;
    },
    async getJsonCode(value) {
        state.loading = true;
        state.jsonWrittableId = value;
        const { data } = await services.fetchGetJsonCode(state.getJsonLink, value);
        state.textareaText = data[state.fieldJson];
        state.tempTextareaText = { ...data[state.fieldJson] };
        state.dialogVisible = true;
        state.loading = false;
    },
    async saveJsonTemp() {
        methods.setJsonCode(false);
    },
    async getJsonCodeOnly(value) {
        state.loading = true;
        state.jsonWrittableId = value;
        const { data } = await services.fetchGetJsonCode(state.getJsonLink, value);
        state.loading = false;
        return data;
    },

    async setJsonCode(apply = true) {
        if (!state.tempTextareaText) {
            return ElMessage({
                message: "Дані залишено без змін",
                type: "warning",
            });
        }
        const value = {
            ...state.jsonWrittableId,
            apply,
            [state.fieldJson]: { ...state.tempTextareaText },
        };
        const { status_code } = await services.fetchGetJsonCode(state.setJsonLink, value);
        if (+status_code === 200) {
            state.dialogVisible = false;
            ElMessage({
                message: `Змінени ${!apply ? `збережено` : "застосовані"} `,
                type: "success",
            });
            if (apply) {
                await methods.fetchDataList();
            }

            return;
        }
    },

    async setTableParams(table_view, table_name) {
        const value = {
            table_name,
            table_view,
        };
        await services.fetchSetTableParams(value);
    },
};

export default {
    state,
    methods,
};

export const accessFunc = (funkName, value) => methods[funkName](value);

const errorMessage = "Request failed with status code 500";

export const errorWrapper = async (request) => {
    state.loading = true;
    try {
        const { data, config } = await request;
        if (data.status_code === "200") {
            if (config.url === "resend_otp") {
                ElMessage({
                    message: data.status_msg,
                    type: "success",
                });
                methods.timer();
            }
        } else {
            ElMessage({
                message: data.status_msg,
                type: "warning",
            });
        }
        state.loading = false;
        return { data };
    } catch (error) {
        if (error?.response?.request?.status === 401) {
            // window.location.reload();
            window.$keycloak.logout();
            return;
        }
        ElMessage({
            message: errorMessage,
            type: "error",
        });
        state.loading = false;
    }
};
