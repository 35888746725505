<template>
  <el-table
    :data="store.state.dataArray"
    :border="true"
    :default-sort="{ prop: 'data', order: 'descending' }"
    
    style="width: 100%; font-family: 'Open Sans', sans-serif"
    class="table"
  >
    <el-table-column
      v-for="(item, index) in clientFieldArr"
      :prop="item.name"
      sortable
      width="auto"
      :min-width="item.fieldLength"
      :key="item"
      :column-key="item.name"
    >
      <!-- <template #default="props">
        <p v-if="'active' === item.name">
          {{ props.row[item.name] ? "Активний" : "Неактивний" }}
        </p>
        <p v-else>{{ props.row[item.name] }}</p>
      </template> -->

      <template #header>
        <div class="search-block" :class="{ show: item.show }">
          <el-input
            @input="filter"
            v-model="item.search"
            size="small"
            placeholder="Type to search"
            class="search-input"
            clearable
            @click.stop
          />
        </div>
        <span>{{ item.fieldName }}</span>
        <span
          @click.stop="toggleShow('show', 'search', index)"
          class="search-icon-filter"
        >
          <el-row>
            <el-button
              v-if="item.dataType === 'String'"
              :icon="icons.Search"
              size="small"
              circle
              class="icon"
            />
          </el-row>
        </span>
      </template>
    </el-table-column>
    <el-table-column label="Operations" width="200">
      <template #default="scope">
        <Dialog
          mainButtontext="editIcon"
          confirm-text="Зміни не буде збережено, ви впевнені?"
          :dialogTitle="
            'Оновлення даних для продукту - ' + scope.row.description
          "
          :row="scope.row"
          button-label-do="Внести зміни"
          formSchemaName="updateProduct"
          function-name="fetchUpdateProduct"
          getDataMethodName="getProductDataById"
          :key="scope.row"
        />

        <el-popconfirm
          confirm-button-text="Так, видаляємо"
          cancel-button-text="Ні, залишаємо"
          :icon="icons.InfoFilled"
          icon-color="#626AEF"
          :title="'Видаляємо ' + scope.row.description + ` ?`"
          @confirm="handleDelete(scope.$index, scope.row)"
          
        >
          <template #reference>
            <el-button type="danger" :icon="icons.Delete" circle />
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { Search, InfoFilled, Edit, Delete } from "@element-plus/icons";
import { inject, toRefs } from "vue";
// import { ElMessage } from "element-plus";
import Dialog from "./Dialog";

export default {
  name: "ProductsTable",
  components: {
    Dialog,
  },

  data() {
    return {
      methodName: "fetchProductList", //from services

     
      clientFieldArr: [
        {
          name: "type_code",
          fieldName: "Код продукту",
          show: "",
          search: "",
          fieldLength: 220,
          dataType: "String",
        },
        {
          name: "description",
          fieldName: "Назва Продукту",
          show: "",
          search: "",
          fieldLength: 250,
          dataType: "String",
        },
      ],
     
    };
  },

  methods: {
    async filter() {
      const queryObj = Object.assign(
        {},
        ...this.clientFieldArr.map((item) => ({ [item.name]: item.search }))
      );
      await this.store.methods.fetchWithTimer(queryObj);
    },

    async handleDelete(index, row) {
      await this.store.methods.fetchRemoveProductById(row);
    },

    toggleShow(field, searchField, index) {
      if (!this.clientFieldArr[index][field]) {
        this.clientFieldArr[index][field] = true;
      } else if (
        this.clientFieldArr[index][field] &&
        !this.clientFieldArr[index][searchField]
      ) {
        this.clientFieldArr[index][field] = false;
      }
    },
  },

  async mounted() {
    this.store.methods.setFetchMethodName(this.methodName);
    await this.store.methods.fetchDataList();
    this.store.state.dataField = this.clientFieldArr.find(
      ({ dataType }) => dataType === "Date"
    )?.name;
  },
  setup() {
    const store = inject("store");
    const icons = {Search, InfoFilled, Edit, Delete};
    return {
      store,
      ...toRefs(store.state),
      icons,
    };
  },
};
</script>
<style>
.table-style {
  font-family: Raleway, serif;
}

.el-message-box {
  width: 1500px !important;
  height: 900px !important;
}

.el-textarea__inner {
  height: 700px !important;
}

/* .el-dialog .jsoneditor {
    border: #509f00 solid 1px;
    border-radius: 8px;
  }
  
  .el-dialog * {
    font-family: "open sans" !important;
  }
  
  .jsoneditor .jsoneditor-menu {
    background-color: #509f00;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .jsoneditor-tree {
    border-radius: 8px;
  } */
</style>
