<template>
  <div class="orders">
    <OrdersTable />
  </div>
</template>

<script>
import OrdersTable from "../components/OrdersTable";

export default {
  name: "Orders",
  components: {
    OrdersTable,
  },
};
</script>
<style scoped>
.orders {
  margin: 20px 20px 0 20px;
}
</style>
