import axios from "axios";
import { accessFunc } from "./store";
import { errorWrapper } from "./store";
// const {VUE_APP_ECOM_PROXY: baseURL} = process.env;
const baseURL = process.env.VUE_APP_ECOM_PROXY_API;

const instance = axios.create({
    // baseURL: "https://ecom-proxy.codot.pro/api/v1/admin/",
    baseURL: `${baseURL}/admin`,
});
export const setToken = async (token='') => {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    // if (localStorage.getItem("token")) {
    //     instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    //         "token"
    //     )}`;
    // }
};
setToken().then();

export const fetchPaymentList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await instance.post("/data/adm_payment_list", { value });
    return data;
};

export const fetchClientList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await instance.post("/data/adm_client_list", { value });
    return data;
};
export const fetchAddNewAdmin = async (newAdminData) => {
    const value = newAdminData;
    const { data } = await errorWrapper(instance.post("/data/adm_user_new", { value }));
    return data;
};
export const fetchUpdateAdmin = async (newAdminData) => {
    const value = newAdminData;
    const { data } = await errorWrapper(
        instance.post("/data/adm_user_update", { value })
    );
    return data;
};
export const fetchRemoveAdminById = async (id) => {
    const value = id;
    const { data } = await instance.post("/data/adm_user_delete", { value });
    return data;
};

export const fetchUserDataById = async (id) => {
    const value = { id };
    const { data } = await errorWrapper(instance.post("/data/adm_user_by_id", { value }));
    return data;
};

export const fetchFormSchema = async (form_id) => {
    const value = { form_id };
    const { data } = await errorWrapper(
        instance.post("/data/form_schema_get", { value })
    );
    return data;
};

export const fetchContractList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await instance.post("/data/adm_contract_list", { value });
    return data;
};
export const fetchApplicationList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await instance.post("/data/adm_application_list", { value });
    return data;
};
export const fetchWidgetList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await instance.post("/data/adm_widget_list", { value });
    return data;
};

export const fetchDoLogin = async (value) => {
    try {
        const { data } = await instance.post("/auth/login", { value });
        await accessFunc("login", data.token);
        return { data };
    } catch (error) {
        return error.response.request.status;
    }

    // instance.defaults.headers.common.Authorization = `Bearer ${data.token}`

    // console.log(instance.defaults.headers);

    // await accessFunc("login", data.token);
    // return { data };
};

export const fetchAccessToken = async (token) => {
    setToken(token)
    const { data } = await instance.get("auth/current");
    // instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    // console.log(instance.defaults.headers);
    return { data };
};

export const fetchAdministratorsList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await instance.post("/data/adm_user_list", { value });
    return data;
};
export const fetchSettingsList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await instance.post("/data/adm_settings_list", { value });
    return data;
};
// *****Acquisitor******
export const fetchAcquisitorList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await instance.post("/data/adm_acquisitor_list", { value });
    return data;
};

// ******Products********
export const fetchProductList = async ({ query_params = {}, limit, page }) => {
    const value = { query_params, limit, page };
    const { data } = await errorWrapper(
        instance.post("/data/adm_product_list", { value })
    );
    return data;
};

export const fetchAddNewProduct = async (newProductData) => {
    const value = newProductData;
    const { data } = await errorWrapper(
        instance.post("/data/adm_product_new", { value })
    );
    return data;
};

export const fetchUpdateProduct = async (newProductData) => {
    const value = newProductData;
    const { data } = await errorWrapper(
        instance.post("/data/adm_product_update", { value })
    );
    return data;
};
export const fetchRemoveProductById = async (id) => {
    const value = id;
    const { data } = await instance.post("/data/adm_product_delete", { value });
    return data;
};
export const fetchProductDataById = async (id) => {
    const value = { id };
    const { data } = await errorWrapper(
        instance.post("/data/adm_product_by_id", { value })
    );
    return data;
};

// export const fetchGetWidgetCode = async (widget_id) => {
//     const value = {widget_id}
//     const {data} = await instance.post("/data/adm_get_widget_by_id", {value})
//     return data
// }
export const fetchGetJsonCode = async (link, value) => {
    // const value = {widget_id}
    const { data } = await errorWrapper(instance.post(`/data/${link}`, { value }));
    return data;
};

export const fetchSetWidgetCode = async (widget_id, widget_object) => {
    const value = { widget_id, widget_object };
    const { data } = await instance.post("/data/adm_put_widget_by_id", { value });
    return data;
    // return {status_code:200}
};

export const fetchSetDuplicateWidget = async (widget_id) => {
    const value = { widget_id };
    const { data } = await errorWrapper(
        instance.post("/data/adm_duplicate_widget", { value })
    );
    return data;
};

export const fetchSetDeleteWidget = async (widget_id) => {
    const value = { widget_id };
    const { data } = await errorWrapper(
        instance.post("/data/adm_delete_widget", { value })
    );
    return data;
};
// Reset Password
export const fetchResetPassword = async (value) => {
    try {
        const { data } = await instance.put("auth/password", { value });
        await accessFunc("login", data.token);
        return data;
    } catch (error) {
        return false;
    }
};

// setTableParams
export const fetchSetTableParams = async (value) => {
    const { data } = await errorWrapper(
        instance.post("/data/auth_user_set_table_view", { value })
    );
    return data;
};

export default {
    fetchPaymentList,
    fetchClientList,
    fetchContractList,
    fetchApplicationList,
    fetchDoLogin,
    fetchAccessToken,
    setToken,
    fetchWidgetList,
    // fetchGetWidgetCode,
    fetchSetWidgetCode,
    fetchAdministratorsList,
    fetchAddNewAdmin,
    fetchRemoveAdminById,
    fetchUpdateAdmin,
    fetchSettingsList,
    fetchGetJsonCode,
    fetchSetDuplicateWidget,
    fetchSetDeleteWidget,
    fetchFormSchema,
    fetchUserDataById,
    fetchProductList,
    fetchAddNewProduct,
    fetchUpdateProduct,
    fetchRemoveProductById,
    fetchProductDataById,
    fetchAcquisitorList,

    fetchResetPassword,
    fetchSetTableParams,
};
