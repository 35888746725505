<template>
  <el-table
    :data="state.dataArray"
    :border="true"
    :default-sort="{ prop: 'data', order: 'descending' }"
    style="width: 100%; font-family: 'Open Sans', sans-serif"
    class="table"
  >
    <el-table-column
      v-for="(item, index) in clientFieldArr"
      :prop="item.name"
      sortable
      width="auto"
      :min-width="item.fieldLength"
      :key="item"
      :column-key="item.name"
    >
      <template #default="props">
        <a v-if="'channel_name' === item.name" :href="props.row[item.name]">{{
          props.row[item.name]
        }}</a>
        <p v-else>{{ props.row[item.name] }}</p>
      </template>

      <template #header>
        <div class="search-block" :class="{ show: item.show }">
          <el-input
            @input="filter"
            v-model="item.search"
            size="small"
            placeholder="Type to search"
            class="search-input"
            clearable
            @click.stop
          />
        </div>
        <span>{{ item.fieldName }}</span>
        <span
          @click.stop="toggleShow('show', 'search', index)"
          class="search-icon-filter"
        >
          <el-row>
            <el-button
              v-if="item.dataType === 'String'"
              :icon="icons.Search"
              size="small"
              circle
              class="icon"
            />
          </el-row>
        </span>
      </template>
    </el-table-column>
    <el-table-column label="Operations" width="170">
      <template #default="scope">
        <el-button
          @click="edit(scope.$index, scope.row)"
          type="success"
          :icon="icons.Edit"
          circle
        />
        <!-- <el-button type="danger" :icon="operationIcons.delete" circle /> -->
      </template>
    </el-table-column>
  </el-table>

  <el-dialog
    v-model="state.dialogVisible"
    :title="'Id: ' + state.jsonWrittableId.setting_id"
    width="80%"
  >
    <v-jsoneditor
      :key="clientFieldArr"
      :value="state.textareaText"
      @input="methods.getTempWidgetJson"
      :plus="false"
      height="500px"
    />

    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="openEditorJson">JSON</el-button>
        <el-button @click="state.dialogVisible = false" type="danger" plain
          >Відмінити</el-button
        >
        <el-button type="success" @click="methods.setJsonCode"
          >Підтвердити</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="isOpenEditorJson"
    :title="'Id: ' + state.jsonWrittableId.setting_id"
    width="40%"
  >
    <el-input
      class="EditorJson"
      v-model="EditorJson"
      :rows="2"
      type="textarea"
      placeholder="Please input"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isOpenEditorJson = false" type="danger" plain
          >Відмінити</el-button
        >
        <el-button type="success" @click="confirmEditorJson"
          >Підтвердити</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  InfoFilled,
  Search,
  Edit,
  Delete,
  CopyDocument,
} from "@element-plus/icons";
import { inject, toRefs } from "vue";
// import {ElMessage} from "element-plus";
import VJsoneditor from "v-jsoneditor/src/index";

export default {
  name: "SettingsTable",
  components: {
    VJsoneditor,
  },

  data() {
    return {
      methodName: "fetchSettingsList", //from services
      jsonParam: {
        getJsonLink: "adm_get_setting_by_id", //from postman
        setJsonLink: "adm_put_setting_by_id", //from postman
        dataField: "setting_object", //name arr from response data
      },
      
      clientFieldArr: [
        {
          name: "setting_code",
          fieldName: "Код інструменту",
          show: "",
          search: "",
          fieldLength: 150,
          dataType: "String",
        },
        {
          name: "setting_name",
          fieldName: "Назва інструменту",
          show: "",
          search: "",
          fieldLength: 150,
          dataType: "String",
        },
        /*  {
            name: 'widget_type',
            fieldName: 'Тип',
            show: '',
            search: '',
            fieldLength: 200,
            dataType: "String",
          },
          {
            name: 'widget_template',
            fieldName: 'Шаблон',
            show: '',
            search: '',
            fieldLength: 230,
            dataType: "String",
          },
          {
            name: 'channel_name',
            fieldName: 'Канал',
            show: '',
            search: '',
            fieldLength: 350,
            dataType: "String",
          },
          {
            name: 'amount',
            fieldName: 'Сума',
            show: '',
            search: '',
            fieldLength: 150,
            dataType: "Number",
          }, {
            name: 'product_code',
            fieldName: 'Код продукту',
            show: '',
            search: '',
            fieldLength: 180,
            dataType: "String",
          }, {
            name: 'widget_code',
            fieldName: 'Код віджкету',
            show: '',
            search: '',
            fieldLength: 150,
            dataType: "String",
          }, {
            name: 'widget_name',
            fieldName: 'Назва віджету',
            show: '',
            search: '',
            fieldLength: 150,
            dataType: "String",
          }, {
            name: 'status',
            fieldName: 'Статус',
            show: '',
            search: '',
            fieldLength: 130,
            dataType: "String",
          }, {
            name: 'widget_template',
            fieldName: 'Шаблон віджету',
            show: '',
            search: '',
            fieldLength: 200,
            dataType: "String",
          }, {
            name: 'widget_type',
            fieldName: 'Тип віджету',
            show: '',
            search: '',
            fieldLength: 200,
            dataType: "String",
          },*/
      ],
      isOpenEditorJson: false,
      EditorJson: "",
     
    };
  },
  methods: {
    myFunc() {
      // console.log(value);
    },
    async filter() {
      const queryObj = Object.assign(
        {},
        ...this.clientFieldArr.map((item) => ({ [item.name]: item.search }))
      );
      await this.methods.fetchWithTimer(queryObj);
    },
    async edit(index, row) {
      await this.methods.getJsonCode({ setting_id: row.setting_id });

      // this.state.setJsonLink = this.setJsonLink
      // await this.store.methods.getWidgetCode(row.widget_id)
    },

    toggleShow(field, searchField, index) {
      if (!this.clientFieldArr[index][field]) {
        this.clientFieldArr[index][field] = true;
      } else if (
        this.clientFieldArr[index][field] &&
        !this.clientFieldArr[index][searchField]
      ) {
        this.clientFieldArr[index][field] = false;
      }
    },
    openEditorJson() {
      this.EditorJson = JSON.stringify(this.state.textareaText);
      this.isOpenEditorJson = true;
    },
    confirmEditorJson() {
      this.state.textareaText = JSON.parse(this.EditorJson);
      this.state.tempTextareaText = this.state.textareaText;
      this.isOpenEditorJson = false;
    },
  },
  onJsonChange() {
    // console.log(value);
  },

  async mounted() {
    await this.methods.setFetchParams(
      // "fetchSettingsList", //from services
      "adm_get_setting_by_id", //from postman
      "adm_put_setting_by_id", //from postman
      "setting_object" //name arr from response data)
    );
    await this.methods.setFetchMethodName(this.methodName);
    await this.methods.fetchDataList();
  },
  setup() {
    const { state, methods } = inject("store");
    const icons = {InfoFilled,
  Search,
  Edit,
  Delete,
  CopyDocument};

    return {
      state,
      methods,
      ...toRefs(state),
      icons,
    };
  },
};
</script>
<style>
.table-style {
  font-family: Raleway, serif;
}

.el-message-box {
  width: 1500px !important;
  height: 900px !important;
}

.el-textarea__inner {
  height: 700px !important;
}

.el-dialog .jsoneditor {
  border: #509f00 solid 1px;
  border-radius: 8px;
}

.el-dialog * {
  font-family: "open sans" !important;
}

.jsoneditor .jsoneditor-menu {
  background-color: #509f00;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.jsoneditor-tree {
  border-radius: 8px;
}
</style>
