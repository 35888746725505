<template>
<div class="products">

  <el-dialog v-model="dialogTableVisible">
    <el-button type="success" @click="getWidgetConfig">Створити</el-button>

    <el-select v-model="widget_id" class="m-2" placeholder="Оберiть вiджет" size="large">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>

    <div v-if="widgetSetting.programs">
      <h3 class="heading">Programs</h3>
      <el-form-item>
        <el-input v-model="widgetSetting.programs" type="textarea" autosize />
      </el-form-item>
    </div>

    <div v-if="widgetSetting.parameters">
      <h3 class="heading">Parameters</h3>
      <el-form-item>
        <el-input v-model="widgetSetting.parameters" type="textarea" autosize />
      </el-form-item>
    </div>

    <div v-if="widgetSetting.risks">
      <h3 class="heading">Risks</h3>
      <el-form-item>
        <el-input v-model="widgetSetting.risks" type="textarea" autosize />
      </el-form-item>
    </div>

    <div v-if="widgetSetting.franchise">
      <h3 class="heading">Franchise</h3>
      <el-form-item>
        <el-input v-model="widgetSetting.franchise" type="textarea" autosize />
      </el-form-item>
    </div>

    <div v-if="widgetSetting.attributes">
      <h3 class="heading">Attributes</h3>
      <el-form-item>
        <el-input v-model="widgetSetting.attributes" type="textarea" autosize />
      </el-form-item>
    </div>

    <div class="action" v-if="selectWidget">
      <el-button type="success" @click="saveWidgetConfig">Зберегти</el-button>
    </div>

  </el-dialog>

  <h1 class="heading-title">Віджети</h1>
  <div class="header-search">
  <!-- <h3 class="heading">Список віджетів</h3> -->
    <div class="head">
<!--      <MixedInput />-->
      <!-- <Button text="+ Додати продукт"/> -->
    </div>

  </div>

  <WidgetsTable />

</div>
</template>

<script>
import { inject } from "vue";
// import Button from "../components/Button";
// import ButtonClear from "../components/ButtonClear";
import WidgetsTable from "../components/WidgetsTable";
// import MixedInput from '../components/MixedInput'
// import Pagination from "../components/Pagination";
// import PaginationClients from "../components/PaginationClients";
// import ExportButton from "../components/ExportButton";
export default {
  name: "Widgets",
  components:{
    WidgetsTable,
    // PaginationClients,
    // ExportButton,
    // Button,
  },
  data(){
    return{
      dialogTableVisible:false,
    }
  },
 
  setup() {
    const { state, methods } = inject("store");

    return {
      state,
      methods,
    };
  },
}
</script>
<style scoped>
.products{
  margin: 20px 20px 0 20px;
}
.heading-title{
  font-weight: 600;
  font-size: 20px;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 32px;
}
.head{
  display: flex;
  align-items: baseline;
}
.header-search{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.heading{
  font-weight: 600;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
}
.clear-section{
  display: flex;
  margin-top: 16px;
  align-items: center;
  margin-bottom: 34px;
}
</style>
