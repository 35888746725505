<template>
    <div class="administrator">
      <h1 class="heading-title">Аквізитор та Менеджер</h1>
      <div class="header">
        <!-- <h3 class="heading">Налаштування</h3> -->
      </div>
      <AcquisitorTable />
    </div>
  </template>
  
  <script>
  import AcquisitorTable from "../components/AcquisitorTable";
  
  export default {
    name: "Acquisitor",
    components:{
      AcquisitorTable,
    }
  }
  </script>
  
  <style scoped>
  .administrator{
    margin: 20px 20px 0 20px;
  }
  .heading-title{
    font-weight: 600;
    font-size: 20px;
    font-family: Raleway;
    margin-bottom: 32px;
  }
  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .heading{
    font-weight: 600;
    font-size: 16px;
    font-family: Raleway;
  }
  .pages-footer{
    display: flex;
  }
  </style>
  