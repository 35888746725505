<template>
  <div  class="main_app_wrapper">
   <Header v-if="store.state.token && store.state.user_id"/>
    <div class="online-products" :class="{'login' : !(store.state.token)}">
    <!--  <div  class="after-auth-block">-->
    <Sidebar v-if="store.state.token"/>
    <div class="loading-block"
         :class="{'active-preloader':store.state.loading}"
         v-loading="store.state.loading"
         :element-loading-text=store.state.loadingText
    />
    <!--  </div>-->
    <div :class="{'router':store.state.token}">
      <router-view />
      <div class="pages-footer" v-if="store.state.token">
        <PaginationClients/>
      </div>
    </div>
  </div>
  </div>
  
</template>
<script>
import Sidebar from "@/components/Sidebar";
import {inject} from "vue";
import PaginationClients from "../components/PaginationClients";
import Header from "../components/Header/Header.vue";

export default {
  components: {
    // EditOnlineProduct1,
    // Payments,
    // Documents,
    // Clients,
    // Clients,
    // Administrators,
    Sidebar,
    PaginationClients,
    Header,
    // OnlineProducts
  },
  async mounted() {
    await this.store.methods.accessToken(this.$router)
    this.store.methods.setTablesClientHeight(document.documentElement.clientHeight)
  },
  setup() {
    const store = inject('store')
    return {
      store
    }
  }
}
</script>
<style>
body {
  font-family: 'Raleway', sans-serif;
}


.online-products {
  display: grid;
  grid-template-columns: 18% 82%;
  margin: 0 auto;
  flex-wrap: wrap;
  height: 100% ;
}

.online-products.login {
  grid-template-columns: 100%;
}

.pages-footer{
  padding-bottom: 10px;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #509F00 !important;
}

.router{
  height: calc(100vh - 64px);
  overflow: auto;

}

</style>
