<template lang="">
  <div class="form_wrapper">
    <div class="input_wrapper">
      <label htmlFor="oldPassword" class="label_cdt">Старий пароль</label>
      <el-input
        id="oldPassword"
        v-model="oldPassword"
        :show-password="true"
        :clearable="true"
        type="password"
        placeholder="Ваш старий пароль"
        size="small"
      />
      <p v-if="requireMessage && !oldPassword" class="requireMessage_cdt">
        Незаповнене поле
      </p>
    </div>
   
      <div class="input_wrapper">
        <label htmlFor="password1" class="label_cdt">Новий пароль</label>
        <el-input
          id="password1"
          v-model="password1"
          :show-password="true"
          :clearable="true"
          type="password"
          placeholder="Ваш новий пароль"
          size="small"
        />
        <p v-if="requireMessage && !password1" class="requireMessage_cdt">
          Незаповнене поле
        </p>
        <p
          v-else-if="
            (requireMessage && password1.length < 12) ||
            (password1 && password1.length < 12)
          "
          class="requireMessage_cdt"
        >
          Пароль меньше (12-ти) символів
        </p>
      </div>
      <div class="input_wrapper">
        <label htmlFor="password2" class="label_cdt"
          >Пароль повторно</label
        >
        <el-input
          id="password2"
          v-model="password2"
          :show-password="true"
          :clearable="true"
          type="password"
          placeholder="Ваш новий пароль"
          size="small"
        />
        <p v-if="requireMessage && !password2" class="requireMessage_cdt">
          Незаповнене поле
        </p>
        <p
          v-else-if="
            (requireMessage && password2 !== password1) ||
            (password2 && password2 !== password1)
          "
          class="requireMessage_cdt"
        >
          Паролі не однакові
        </p>
      </div>
   
    <div style="text-align: center; margin-top: 35px">
      <el-button @click="submitForm" type="success">Змінити</el-button>
    </div>
  </div>
</template>
<script>
import { ElNotification } from "element-plus";

export default {
  emits: ["submitNewPass"],
  data() {
    return {
      password1: "",
      password2: "",
      oldPassword: "",
      requireMessage: false,
    };
  },
  methods: {
    submitForm() {
      if (!this.password1 || !this.password2 || !this.oldPassword) {
        this.requireMessage = true;
        return;
      }

      if (
        !isNaN(
          this.password1.match(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%]).{12,}$/
          )
        )
      ) {
        ElNotification({
          title: `Новий пароль  не валідний`,
          message: `Пароль повинен складатися не менше ніж із 12 (дванадцяти) символів.
  У паролі повинні бути присутні всі символи з таких категорій:
  прописні (заголовні) літери англійського алфавіту від A до Z,
  малі літери англійського алфавіту від a до z,
  десяткові цифри (від 0 до 9),
  спецсимволи (@,!, $, #, %)`,
          type: "warning",
          position: "top-left",
          duration: 20000,
        });

        return;
      }

      if (this.password1 !== this.password2) {
        ElNotification({
          title: `Нові паролі не однакові`,
          type: "warning",
          position: "top-left",
        });

        return;
      }

      this.$emit("submitNewPass", {
        password: this.password1,
        oldPassword: this.oldPassword,
      });
    },
  },
};
</script>
<style lang="css" scoped>
.form_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 45px;
}
.input_wrapper {
  width: 400px;
  display: flex;
  align-items: baseline;
  /* flex-direction: column; */
  position: relative;
}
.innerBox {
  display: flex;
  gap: 30px;
}
.label_cdt {
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 1.6;
  width: 260px;
}

.requireMessage_cdt {
  position: absolute;
  top: 35px;
  right: 130px;
  color: red;
  font-size: 12px;
}
</style>
