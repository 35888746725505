<template>
  <div></div>
  <!-- <div class="login">
    <div class="login-form">
      <img src="../assets/img/logo-black.png" alt="Logo" class="logo" />
      <div class="form-group">
        <span class="text-gray-600 form-label">Логін</span>
        <el-input v-model="user_id" class="w-50 m-2" placeholder="Логін" />
        <p
          v-if="(isFieldsCheck && !user_id) || errorField === 'email'"
          class="errorText"
        >
          {{ this.errorField ? "Невірний E-mail" : "Незаповнене поле" }}
        </p>
      </div>
      <div class="form-group">
        <span class="text-gray-600 form-label">Пароль</span>
        <el-input
          v-model="password"
          class="w-50 m-2"
          type="password"
          placeholder="Пароль"
        />
        <p
          v-if="(isFieldsCheck && !password) || errorField === 'password'"
          class="errorText"
        >
          {{ this.errorField ? "Невірний пароль" : "Незаповнене поле" }}
        </p>
      </div>
      <div class="button-group">
        <el-button type="primary" @click="enter">Вхід</el-button>
      </div> -->
      <!-- <div class="support-block">
        <div class="captcha">
          Продовжуючи, Ви погоджуєтесь з
          <span class="link pointer underline">Умовами обслуговування</span>
          <div>reCAPTCHA.</div>
        </div>
        <div class="captcha">
          Виникли проблеми з авторизацією?
          <span class="bold pointer underline">Відновити пароль.</span>
        </div>
      </div> -->
    <!-- </div>
    <div class="ring1" />
    <div class="ring2" />
    <div class="ring3" />
  </div> -->
</template>

<script>
import { inject, ref } from "vue";

export default {
  name: "/",
  data() {
    return {
      isFieldsCheck: false,
      errorField: "",
    };
  },
  methods: {
    async enter() {
      this.isFieldsCheck = true;
      const { user_id, password } = this;
      if (!user_id || !password) {
        return;
      }
      const value = {
        user_id,
        password,
      };
      const resultError = await this.store.methods.doLogin(value, this.$router);
      if (resultError) {
        if (resultError === 404) {
          this.errorField = "email";
          return;
        }
        if (resultError === 401) {
          this.errorField = "password";
          return;
        }
      }
    },
  },
  watch: {
    user_id() {
      if (this.errorField !== "") {
        this.errorField = "";
      }
    },
    password() {
      if (this.errorField !== "") {
        this.errorField = "";
      }
    },
  },
  setup() {
    const user_id = ref("");
    const password = ref("");
    const store = inject("store");

    return {
      user_id,
      password,
      store,
    };
  },
};
</script>

<style scoped>
.login {
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #373f4f;
}

.login-form {
  color: #373f4f;
  min-width: 300px;
  background-color: white;
  padding: 50px 125px;
  border-radius: 16px;
  position: absolute;
  z-index: 2;
}
.online-products.login .el-input .el-input__inner {
  color: red;
}
.logo {
  display: flex;
  margin: 0 auto;
  width: 150px;
}

.form-group {
  margin-bottom: 35px;
  position: relative;
}

.form-label {
  display: block;
  margin-bottom: 10px;
  color: #443641;
}

.errorText {
  position: absolute;
  top: 70px;
  left: 15px;

  font-size: 15px;
  font-weight: 500;
  color: #f56c6c;
}
.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-button--primary {
  width: 100px;
}

.button-group-link {
  border-bottom: 1px solid;
  cursor: pointer;
}
.support-block {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.captcha {
}
.bold {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.support-block .link {
  color: #509f00;
}

.ring1 {
  position: absolute;
  top: 50px;
  left: 50px;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  background-color: #373f4f;
  border: 1px solid rgba(85, 91, 121, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(85, 91, 121, 0.2) inset;
}
.ring2 {
  position: absolute;
  top: 300px;
  left: 100px;
  border-radius: 100%;
  height: 180px;
  width: 180px;
  background-color: #373f4f;
  border: 1px solid rgba(85, 91, 121, 0.2);
  box-shadow: -4px -4px 8px 0px rgba(85, 91, 121, 0.2) inset;
}
.ring3 {
  position: absolute;
  top: 300px;
  right: 300px;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  background-color: #373f4f;
  border: 1px solid rgba(30, 33, 44, 0.38);
  box-shadow: 4px -4px 8px 0px rgba(30, 32, 42, 0.5) inset;
}
</style>
