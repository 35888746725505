<template lang="">
  <header class="header">
    <div class="inner_box">
      <div class="logo">
        <img
          class="pointer"
          @click="toMainPage($router)"
          src="../../assets/img/logo-white.png"
          alt="Logo"
        />
      </div>
      <div class='operation_wrapper'>
      <!-- <CustomSvg class='operation_icon' nameSvg='icon-bell'/>
      <CustomSvg class='operation_icon' nameSvg='icon-pencil'/>
      <CustomSvg class='operation_icon' nameSvg='icon-language'/> -->
      <UserMenu />
      </div>
      
    </div>
  </header>
</template>
<script>
import { inject } from "vue";
import UserMenu from "./UserMenu/UserMenu.vue";
// import CustomSvg from "../CustomSvg.vue";
export default {
  components: {
    UserMenu,
    // CustomSvg,
  },
  data() {
    return {};
  },
  methods: {
    toMainPage(router) {
      if (this.state.user_role === "administrator") {
        router.push("/Widgets");
        return;
      }
      router.push("/Clients");
    },
  },
  setup() {
    const { state } = inject("store");

    return {
      state,
    };
  },
};
</script>
<style lang="css" scoped>
.header {
  background-color: #64a70b;
  padding: 5px;
}

.logo > img {
  object-fit: contain;
  width: 200px;
  height: 50px;
  cursor: pointer;
}
.inner_box {
  width: 92vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.operation_wrapper{
  display: flex;
  gap: 20px;
}
.operation_icon{
  color: white;
  cursor: pointer;
  width: 2em;
  height: 2em;
  stroke-width: 0;
}
</style>
