<template>
  <el-table
    :data="store.state.dataArray"
    :default-sort="{ prop: 'data', order: 'descending' }"
    :border="true"
    style="width: 100%; font-family: 'Open Sans', sans-serif"
    class="table"
  >
    <el-table-column
      v-for="(item, index) in clientFieldArr"
      :prop="item.name"
      sortable
      width="auto"
      :min-width="item.fieldLength"
      :key="item"
      :column-key="item.name"
    >
      <template #default="props">
        <p v-if="'active' === item.name">
          {{ props.row[item.name] ? "Активний" : "Неактивний" }}
        </p>
        <p v-else>{{ props.row[item.name] }}</p>
      </template>

      <template #header>
        <div class="search-block" :class="{ show: item.show }">
          <el-input
            @input="filter"
            v-model="item.search"
            size="small"
            placeholder="Type to search"
            class="search-input"
            clearable
            @click.stop
          />
        </div>
        <span>{{ item.fieldName }}</span>
        <span
          @click.stop="toggleShow('show', 'search', index)"
          class="search-icon-filter"
        >
          <el-row>
            <el-button
              v-if="item.dataType === 'String'"
              :icon="icons.Search"
              size="small"
              circle
              class="icon"
            />
          </el-row>
        </span>
      </template>
    </el-table-column>
    <el-table-column label="Operations" width="120">
      <template #default="scope">
        <Dialog
          mainButtontext="editIcon"
          confirm-text="Зміни не буде збережено, ви впевнені?"
          :dialogTitle="
            'Оновлення даних для користувача - ' + scope.row.user_name
          "
          :row="scope.row"
          button-label-do="Внести зміни"
          function-name="fetchUpdateAdmin"
          formSchemaName="formUpdateUser"
          getDataMethodName="getUserDataById"
          :key="scope.row"
        />
        <!--        <el-button text @click="edit(scope.$index, scope.row)">Редагувати</el-button>-->
        <el-popconfirm
          confirm-button-text="Так, видаляємо"
          cancel-button-text="Ні, залишаємо"
          :icon="icons.InfoFilled"
          icon-color="#626AEF"
          :title="'Видаляємо ' + scope.row.user_name"
          @confirm="handleDelete(scope.$index, scope.row)"
         
        >
          <template #reference>
            <el-button type="danger" :icon="icons.Delete" circle />
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { Search, InfoFilled, Edit, Delete } from "@element-plus/icons";
import { inject, toRefs } from "vue";
import { ElMessage } from "element-plus";
import Dialog from "./Dialog";

export default {
  name: "UsersTable",
  components: {
    Dialog,
  },

  data() {
    return {
      methodName: "fetchAdministratorsList", //from services
    

      clientFieldArr: [
        {
          name: "user_name",
          fieldName: "П І Б",
          show: "",
          search: "",
          fieldLength: 250,
          dataType: "String",
        },
        {
          name: "user_email",
          fieldName: "Email",
          show: "",
          search: "",
          fieldLength: 220,
          dataType: "String",
        },
        {
          name: "user_phone",
          fieldName: "Телефон",
          show: "",
          search: "",
          fieldLength: 200,
          dataType: "String",
        },
        {
          name: "active",
          fieldName: "Статус",
          show: "",
          search: "",
          fieldLength: 120,
          dataType: "String",
        },
        {
          name: "user_role",
          fieldName: "Роль",
          show: "",
          search: "",
          fieldLength: 180,
          dataType: "String",
        },
      ],
     
    };
  },

  methods: {
    // myFunc(value) {
    //   console.log(value);
    // },
    async filter() {
      const queryObj = Object.assign(
        {},
        ...this.clientFieldArr.map((item) => ({ [item.name]: item.search }))
      );
      await this.store.methods.fetchWithTimer(queryObj);
    },
    async edit(index, row) {
      ElMessage({
        message: `Дані  адміністратора ${row.user_name} "змінено"`,
        type: "success",
      });
      // await this.store.methods.getWidgetCode(row.widget_id)
    },
    async resetPass(index, row) {
      ElMessage({
        message: `Пароль адміністратора ${row.user_name} "скинуто"`,
        type: "warning",
      });
      // await this.store.methods.getWidgetCode(row.widget_id)
    },
    async handleDelete(index, row) {
      // console.log(index);
      const resp = await this.store.methods.fetchRemoveAdminById(row);
      if (+resp === 200) {
        ElMessage({
          message: `Адміністратора ${index + 1}  ${
            row.user_name
          } "видалено"`,
          type: "success",
        });
      }
    },

    toggleShow(field, searchField, index) {
      if (!this.clientFieldArr[index][field]) {
        this.clientFieldArr[index][field] = true;
      } else if (
        this.clientFieldArr[index][field] &&
        !this.clientFieldArr[index][searchField]
      ) {
        this.clientFieldArr[index][field] = false;
      }
    },
  },
  // onJsonChange(value) {
  //   console.log("value:", value);
  // },

  async mounted() {
    this.store.methods.setFetchMethodName(this.methodName);
    await this.store.methods.fetchDataList();
    this.store.state.dataField = this.clientFieldArr.find(
      ({ dataType }) => dataType === "Date"
    )?.name;
  },
  setup() {
    const store = inject("store");
    const  icons= {
      Search, InfoFilled, Edit, Delete
    };
    return {
      store,
      icons,
      ...toRefs(store.state),
    };
  },
};
</script>
<style>
.table-style {
  font-family: Raleway, serif;
}

.el-message-box {
  width: 1500px !important;
  height: 900px !important;
}

.el-textarea__inner {
  height: 700px !important;
}

.el-dialog .jsoneditor {
  border: #64a70b solid 1px;
  border-radius: 8px;
}

.el-dialog * {
  font-family: "open sans" !important;
}

.jsoneditor .jsoneditor-menu {
  background-color: #64a70b;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.jsoneditor-tree {
  border-radius: 8px;
}
</style>

<!--<script>-->
<!--export default {-->
<!--  name: "AdministratorsTable",-->
<!--  data() {-->
<!--    return {-->
<!--      tableData: [-->
<!--        {-->
<!--          name: 'Админ Иван Иванович',-->
<!--          email: 'Admin@gmail.com',-->
<!--          tel: '+38 (050) 111 22 33',-->
<!--          lang: 'ru',-->
<!--          status: 'Неактивный',-->
<!--          action: '',-->
<!--        },-->
<!--        {-->
<!--          name: 'Админ Иван Иванович',-->
<!--          email: 'Admin@gmail.com',-->
<!--          tel: '+38 (050) 111 22 33',-->
<!--          lang: 'ru',-->
<!--          status: 'Неактивный',-->
<!--          action: '',-->
<!--        },-->
<!--        {-->
<!--          name: 'Админ Иван Иванович',-->
<!--          email: 'Admin@gmail.com',-->
<!--          tel: '+38 (050) 111 22 33',-->
<!--          lang: 'ru',-->
<!--          status: 'Неактивный',-->
<!--          action: '',-->
<!--        },-->
<!--      ],-->
<!--    }-->
<!--  },-->
<!--}-->
<!--</script>-->
