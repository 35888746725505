<template>
  <div class="pagination">
    <div class="page-size pagination-left">
      <el-pagination background
                     v-model=pageSizes
                     :page-sizes="[10,20, 40, 60, 80]"
                     :page-size="10"
                     
                     :total=6
                     layout="sizes"
                     @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <div class="page-number pagination-right">
      <el-pagination background
                     v-model=pagesNumber
                     :page-size="1"
                     
                     :total=store.state.query_size
                     layout="prev, pager, next"
                     @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- <ExportButton text="Експортувати у XLS"/> -->
  </div>
</template>

<script>
// import store from "@/store"
import {inject, ref} from "vue";
// import ExportButton from "./ExportButton";
// import {fetchApplicationList} from "../services";

export default {
  name: "PaginationClients",
  data(){
  return {
    currentPage2:1,
    pagesNumber:ref(''),
    pageSizes:ref(''),
  }
  },
  components:{
    // ExportButton,
  },
  methods:{
    async handleSizeChange(value){
      await this.store.methods.setLimit(value)
      await this.store.methods.fetchDataList()
    },

    async handleCurrentChange(page){
      await this.store.methods.setPage(page)
         await this.store.methods.fetchDataList()
    }
  },
  mounted() {
    this.pagesNumber = +this.store.state.query_size;
  },
  setup(){
    const store = inject('store')
    return {
      store
    }
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 28px 40px 0;
}
.pagination-left{
  margin-left: -10px;
}
.pagination-right{
  margin-left: 400px;
}
</style>
