<template>
  <div class="wrapper">
    <!-- <el-tabs v-model="activeTab" class="demo-tabs">
      <el-tab-pane
        v-for="tab in titlesTabsArr"
        :key="tab"
        :label="tab.toLocaleUpperCase()"
        :name="tab"
      />
    </el-tabs> -->
    
    <el-select v-model="activeTab"  >
    <el-option
      v-for="tab in titlesTabsArr"
      :key="tab"
      :label="tab.toLocaleUpperCase()"
      :value="tab"
    />
  </el-select>
  </div>
  <!-- COMMON_SETTING -->

  <template v-if="activeTab === 'common_setting'">
    <div class="common_setting-wrapper">
      <div class="main-options">
        <!-- ******LeftSIDE***** -->
        <div class="left-options">
          <div class="main-settings-inputs-wrapper">
            <template
              v-for="item in Object.keys(
                this.store.state.textareaText.common_setting
              )"
              :key="item"
            >
              <!-- Common -->
              <div
                v-if="
                  item !== 'reCAPTCHA_v3' &&
                  item !== 'reCAPTCHA_v2' &&
                  item !== 'comments' &&
                  item !== 'widget_enable'&&
                  item !=='active'
                "
                class="common_setting_input-wrapper"
              >
                <label class="common_setting_label">{{ item }}</label>
                <el-input
                  v-model="this.store.state.textareaText.common_setting[item]"
                  class="common_setting_input"
                  placeholder="item"
                />
              </div>
            </template>
          </div>

          <!-- reCAPTCHA_v2 -->
          <h3 class="common_setting_subtitle">reCAPTCHA_v2</h3>
          <div class="main-settings-inputs-wrapper">
            <template
              v-for="item in Object.keys(
                this.store.state.textareaText.common_setting.reCAPTCHA_v2
              )"
              :key="item"
            >
              <div class="common_setting_input-wrapper">
                <label class="common_setting_label">{{ item }}</label>
                <el-input
                  v-model="
                    this.store.state.textareaText.common_setting.reCAPTCHA_v2[
                      item
                    ]
                  "
                  class="common_setting_input"
                  placeholder="item"
                />
              </div>
            </template>
          </div>

          <!-- reCAPTCHA_v3 -->
          <h3 class="common_setting_subtitle">reCAPTCHA_v3</h3>
          <div class="main-settings-inputs-wrapper">
            <template
              v-for="item in Object.keys(
                this.store.state.textareaText.common_setting.reCAPTCHA_v3
              )"
              :key="item"
            >
              <div class="common_setting_input-wrapper">
                <label class="common_setting_label">{{ item }}</label>
                <el-input
                  v-model="
                    this.store.state.textareaText.common_setting.reCAPTCHA_v3[
                      item
                    ]
                  "
                  class="common_setting_input"
                  placeholder="item"
                />
              </div>
            </template>
          </div>
        </div>

        <!-- ******RigthSIDE***** -->
        <div class="rigth-options">
          <div class="common_setting_input-wrapper checkbox">
            <label class="common_setting_label">active</label>
            <el-checkbox
              v-model="this.store.state.textareaText.common_setting.active"
              size="large"
            />
          </div>
          <div class="common_setting_input-wrapper">
            <label class="common_setting_label">comments</label>
            <el-input
              class="comment common_setting_input"
              v-model="this.store.state.textareaText.common_setting.comments"
              type="textarea"
              placeholder="some comment..."
            />
          </div>
        </div>
      </div>
    </div>
  </template>

  <!-- UI_SETTING --> 
  <div v-else-if="activeTab === 'ui_setting' && this.store.state.textareaText.ui_setting?.colors ">
    <div class="common_setting-wrapper">
      <h3 class="common_setting_subtitle">Colors</h3>
      <div class="main-settings-inputs-colors-wrapper">
        <template
          v-for="item in Object.keys(
            this.store.state.textareaText.ui_setting.colors
          )"
          :key="item"
        >
          <!-- Colors -->
          <div class="common_setting_input_color-wrapper">
            <label class="common_setting_label">{{ item }}</label>
            <div class="color-wrapper">
              <el-input
                v-model="this.store.state.textareaText.ui_setting.colors[item]"
                class="common_setting_input"
                placeholder="item"
              />
              <el-color-picker
                class="color-picker"
                v-model="this.store.state.textareaText.ui_setting.colors[item]"
              />
            </div>
          </div>
        </template>
      </div>

      <!-- Fonts and Banner-->
      <h3 class="common_setting_subtitle">Fonts and Banner</h3>
      <div class="main-settings-inputs-wrapper">
        <template
          v-for="item in Object.keys(
            this.store.state.textareaText.ui_setting.fonts
          )"
          :key="item"
        >
          <div class="common_setting_input-wrapper">
            <label class="common_setting_label">{{ item }}</label>
            <el-input
              v-model="this.store.state.textareaText.ui_setting.fonts[item]"
              class="common_setting_input"
              placeholder="item"
            />
          </div>
        </template>
        <div class="common_setting_input-wrapper">
          <label class="common_setting_label">banner_img_url</label>
          <el-input
            v-model="this.store.state.textareaText.ui_setting.banner_img_url"
            class="common_setting_input"
            placeholder="item"
          />
        </div>
        <div class="common_setting_input-wrapper">
          <label class="common_setting_label">banner_link_url</label>
          <el-input
            v-model="this.store.state.textareaText.ui_setting.banner_link_url"
            class="common_setting_input"
            placeholder="item"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- acquiring_commission -->
  <div v-else-if="activeTab === 'acquiring_commission'">
    <v-jsoneditor
      :value="this.store.state.textareaText.acquiring_commission"
      @input="(e) => onJsoneditorInput(e, 'acquiring_commission')"
      :plus="false"
      height="425px"
    />
  </div>

  <!-- LAYOUT -->
  <div v-else-if="activeTab === 'layout'">
    <v-jsoneditor
      :value="this.store.state.textareaText.layout"
      @input="(e) => onJsoneditorInput(e, 'layout')"
      :plus="false"
      height="425px"
    />
  </div>

  <!-- WIDGET_PARAMETERS -->
  <div v-else-if="activeTab === 'widget_parameters'">
    <v-jsoneditor
      :value="this.store.state.textareaText.widget_parameters"
      @input="(e) => onJsoneditorInput(e, 'widget_parameters')"
      :plus="false"
      height="425px"
    />
  </div>

  <!-- PACKAGES -->
  <div v-else-if="activeTab === 'packages'">
    <v-jsoneditor
      :value="this.store.state.textareaText.packages"
      @input="(e) => onJsoneditorInput(e, 'packages')"
      :plus="false"
      height="425px"
    />
  </div>

  <!-- ACQUISITOR_MANAGER -->
  <div v-else-if="activeTab === 'acquisitor_manager'">
    <v-jsoneditor
      :value="this.store.state.textareaText.acquisitor_manager"
      @input="(e) => onJsoneditorInput(e, 'acquisitor_manager')"
      :plus="false"
      height="425px"
    />
  </div>
  <!-- All Default -->

  <div v-else>
    <v-jsoneditor
      :value="this.store.state.textareaText[this.activeTab]"
      @input="(e) => onJsoneditorInput(e, `${this.activeTab}`)"
      :plus="false"
      height="425px"
    />
  </div>
</template>
<script>
import { inject } from "vue";
import VJsoneditor from "v-jsoneditor/src/index";
export default {
  components: {
    VJsoneditor,
  },
  props: {
    propObject: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      titlesTabsArr: Object.keys(this.store.state.textareaText),
      activeTab: Object.keys(this.store.state.textareaText)[0],
    };
  },
  methods: {
    onJsoneditorInput(event, vModel) {
      if (!event?.timeStamp) {
        this.store.state.tempTextareaText[`${vModel}`] = event;
      }
    },
  },

  setup() {
    const store = inject("store");
    return {
      store,
    };
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  /* justify-content: center; */
  margin-bottom: 10px;
}
.demo-tabs {
  display: inline-flex;
}

.common_setting-wrapper {
  margin-top: 10px;
  height: 400px;
  overflow-y: scroll;
  padding-bottom: 15px;
  padding-left: 30px;
}
.main-settings-inputs-wrapper {
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
}
.main-options {
  display: flex;
  gap: 50px;
}
.rigth-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 53px;
  font-size: 15px;
}
.common_setting_input-wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.common_setting_input-wrapper.checkbox {
  width: 400px;
  display: flex;
  gap: 15px;
  flex-direction: row;
  align-items: center;
}

.common_setting_label {
  font-size: 14px;
  font-weight: 700;
  /* text-transform: uppercase; */
}

.common_setting_input {
  font-size: 15px;
  /* height: 30px !important; */
}
.common_setting_subtitle {
  font-size: 20px;
  font-weight: 700;
  /* text-align: center; */
  margin-top: 30px;
  margin-bottom: 35px;
  text-decoration: underline;
}
/* Colors */
.main-settings-inputs-colors-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.common_setting_input_color-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.common_setting_input_color-wrapper .common_setting_input {
  width: 200px;
}
.color-wrapper {
  display: flex;
  justify-content: space-between;
  width: 270px;
}
</style>
