<template lang="">
    <el-dropdown trigger="hover">
        <div class="user_wrapper">
            <p class="user_name">{{ store.state.user_id[0] }}</p>
        </div>
        <template #dropdown>
            <el-dropdown-menu>
                <p
                    style="
                        text-transform: uppercase;
                        margin: 10px auto;
                        text-align: center;
                    "
                >
                    {{ store.state.user_role }} :
                </p>
                <p style="margin: 10px auto; text-align: center; font-weight: 700">
                    {{ store.state.user_id }}
                </p>
                <el-dropdown-item>
                    <p @click="() => startDialog()">Налаштування</p>
                </el-dropdown-item>
                <el-dropdown-item>
                    <!-- <p @click="() => logout($router)">Вихід</p> -->
                    <p @click="() => logoutKeycloak()">Вихід</p>
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>

    <el-dialog v-model="dialog" :lock-scroll="true" width="40%">
        <UserPersonalSettings @submit="submitInDialog" v-if="dialog" />

        <template #footer>
            <span class="dialog-footer">
                <el-button
                    type="danger"
                    @click="
                        () => {
                            this.dialog = false;
                        }
                    "
                    >Відміна</el-button
                >
            </span>
        </template>
    </el-dialog>
</template>
<script>
import { inject } from "vue";
import UserPersonalSettings from "./UserPersonalSettings.vue";
import { ElNotification } from "element-plus";
import services from "../../../services";

export default {
    components: { UserPersonalSettings },
    name: "UserMenu",
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        logout(router) {
            localStorage.clear();
            router.go(0);
        },
        logoutKeycloak() {
            window.$keycloak.logout();
        },
        startDialog() {
            this.dialog = true;
        },

        async changePassword({ password, oldPassword }) {
            const value = {
                password,
                oldPassword,
            };

            const result = await services.fetchResetPassword(value);
            if (result?.token) {
                localStorage.setItem("token", result.token);
                await services.setToken();
                this.store.state.token = result.token;
                this.dialog = false;
                ElNotification({
                    title: `Пароль успішно змінено на новий`,
                    type: "success",
                    position: "top-left",
                });

                return;
            }
            ElNotification({
                title: `Неправильний старий пароль`,
                type: "warning",
                position: "top-left",
            });
        },

        submitInDialog({ operation, value }) {
            if (operation === "changePassword") {
                this.changePassword(value);
                return;
            }
        },
    },
    setup() {
        const store = inject("store");
        // const bcrypt = bcrypt;
        return {
            store,
            // bcrypt,
        };
    },
};
</script>
<style lang="css" scoped>
.user_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}
.user_name {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    color: #64a70b;
}
</style>
