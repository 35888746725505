<template lang="">
 
    <el-button @click="()=>{this.dialog=true}" style="width:45px; height:45px" type="primary"  circle >
      <Operation style="width: 1.5em; height: 1.5em" />
    </el-button>


    <el-dialog
    v-model="dialog"
    width="700px"
  >
  <div style="text-align: center">
    <el-transfer
        v-if='dialog'
        v-model="listValue"
        filterable
        filter-placeholder="Назва поля..."
        :titles="['Загальні поля', 'Відобразити']"
        :data="this.generateData(fieldArr)"
        target-order='push'
        @right-check-change="(value)=>{this.rigthList=value}"
      >
    
      <template  #right-footer >
        <div class='footer_btn'>
            <el-button :disabled="rigthList.length===1?false:true" :icon="icons.Top"  @click='()=>operationUp(this.rigthList[0])' size="small"/>
         <el-button :disabled="rigthList.length===1?false:true" :icon="icons.Bottom"  @click='()=>operationDown(this.rigthList[0])' size="small"/>
      
        </div>
        </template>
        <template  #left-footer >
        <div class='footer_btn'>
         
        </div>
        </template>
    </el-transfer>

  </div>
  
    <template #footer>
      <span class="dialog-footer">
       
        <el-button @click="()=>{this.dialog = false}" type="danger" plain
          >Відмінити</el-button
        >
        <el-button type="primary" @click="onConfirm"
          >Застосувати</el-button
        >
      </span>
    </template>
  </el-dialog>

    


</template>
<script>
import {Top, Bottom, Operation} from "@element-plus/icons"
export default {
  name: "TableCustomizer",
  emits:['onConfirm'],
  components:{
    Operation
  },
  props: {
    fieldArr:{
        type:Array,
        default:()=>[]
    },
    profileList:{
        type:Array,
        default:()=>[]
    }

  },
  data() {
    return {
      dialog: false,
      listValue: this.profileList,
      rigthList:[]
    };
  },
  methods: {
    filterMethod(query, item) {
      return item.initial.toLowerCase().includes(query.toLowerCase());
    },
    generateData(arr) {
      const data = [];
      
    //   const initials = ["CA", "IL", "MD", "TX", "FL", "CO", "CT"];
    arr.forEach((el) => {
        data.push({
          label: el.fieldName,
          key: el.name,
        //   initial: initials[index],
        });
      });
      return data;
    },
    operationUp(element){
        const oldIndex = this.listValue.indexOf(element);
        if(oldIndex===-1 || oldIndex===0 ){
            return
        }
        const newArr = [...this.listValue];

        newArr.splice(oldIndex, 1);
        newArr.splice(oldIndex-1, 0, element);
        this.listValue = newArr;
    },
    operationDown(element){
        const oldIndex = this.listValue.indexOf(element);
        if(oldIndex===-1 || oldIndex===this.listValue.length-1 ){
            return
        }
        const newArr = [...this.listValue];

        newArr.splice(oldIndex, 1);
        newArr.splice(oldIndex+1, 0, element);
        this.listValue = newArr;
    },
    onConfirm(){
        this.$emit("onConfirm", this.listValue);
        this.dialog=false;
    }
  },
  setup(){
    const icons={Top, Bottom, Operation};
    return{
        icons,
    }
  },
  watch:{
    "profileList"(value){
      this.listValue = value
      
    }
  }
 
};
</script>
<style lang="css" scoped>



.footer_btn{
    display: flex;
    justify-content: center;
    padding-top: 3px;

    

}
</style>
